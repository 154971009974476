import { ApolloError, ApolloQueryResult, gql, OperationVariables, useQuery } from '@apollo/client';
import { ApplicationDetailsResponseApplication, ApplicationDetailsResponse } from '../details.types';

interface Result {
  application: ApplicationDetailsResponseApplication | undefined;
  loading: boolean;
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<ApplicationDetailsResponse>>;
  error?: ApolloError;
}

export const ApplicationDetails = gql`
  query ApplicationDetails($applicationId: Int!) {
    application(applicationId: $applicationId) {
      id
      firstName
      lastName
      status
      applicantEmail
      externalApplicationId
      phoneNumber
      lgCoverageOverride
      sdrCoverageOverride
      screeningStatus
      normalizedFields {
        riskCategory
        recordCheck {
          ficoScore
          isFirstNameMatched
          isLastNameMatched
          isSsnMatched
          isDateOfBirthMatched
          isAddressMatched
        }
        autoUWVersion
      }
      policies {
        id
        bondNumber
        status
        createdAt
        deal {
          carrier
        }
        lease {
          addressUnit
          leaseStartDate
          leaseEndDate
          property {
            displayName
            mainAddress
            city
            state
            zipCode
            llc
          }
          organization {
            name
          }
          rentCoverage
          depositsCoverage
          freeRent
          prepaidRent
        }
        premium {
          amount
          context {
            monthlyRent
            coverageMonths
            amountSDR
            premiumRate
            penalSum
            premiumAmountWithoutUpfrontDiscount
            premiumAmountBeforeTax
          }
        }
        documents {
          id
          fileId
          type
        }
        previousPolicyId
      }

      lease {
        id
        propertyId
        leaseStartDate
        leaseEndDate
        monthlyRent
        addressUnit
        rentCoverage
        depositsCoverage
        freeRent
        prepaidRent

        property {
          displayName
          mainAddress
          state
          city
          zipCode
          primaryRole {
            contact {
              firstName
              lastName
              email
              phoneNumber
            }
          }
        }
      }

      contact {
        firstName
        lastName
        email
        phoneNumber
      }

      deal {
        id
      }

      invitation {
        type
      }

      reply {
        answers {
          fieldId
          value
        }
      }

      payment {
        amountPaid
        amountRefunded
        outstandingAmount
        history {
          id
          status
          paymentMethod
          amount
          date
        }
      }

      quote {
        # for payment section
        premiumAmount
        premiumAmountWithoutUpfrontDiscount
        lgPremiumAmountWithoutUpfrontDiscount
        sdrPremiumAmountWithoutUpfrontDiscount

        penalSum
        amountSDR
        coverageMonths
        premiumRate
        rateFilingData
        riskCategory
        carrier
        premium
        addRiskPremium
        lgPremiumAmount
        sdrPremiumAmount
      }

      renterProvidedData {
        leaseMonths
        monthlyRent
        apartmentNumber
      }

      settings {
        comment
        isMonthlyPayment
        isVouchedPremium
        firstMonthlyPaymentDate
      }
    }
  }
`;

export const ApplicationDetailsWithPropertyCoverages = gql`
  query ApplicationDetails($applicationId: Int!) {
    application(applicationId: $applicationId) {
      id
      firstName
      lastName
      status
      applicantEmail
      externalApplicationId
      phoneNumber
      lgCoverageOverride
      sdrCoverageOverride
      screeningStatus
      normalizedFields {
        riskCategory
        recordCheck {
          ficoScore
          isFirstNameMatched
          isLastNameMatched
          isSsnMatched
          isDateOfBirthMatched
          isAddressMatched
        }
        autoUWVersion
      }
      policies {
        id
        bondNumber
        status
        createdAt
        deal {
          carrier
        }
        lease {
          addressUnit
          leaseStartDate
          leaseEndDate
          property {
            displayName
            mainAddress
            city
            state
            zipCode
            llc
          }
          organization {
            name
          }
          rentCoverage
          depositsCoverage
          freeRent
          prepaidRent
        }
        premium {
          amount
          context {
            monthlyRent
            coverageMonths
            amountSDR
            premiumRate
            penalSum
            premiumAmountWithoutUpfrontDiscount
            premiumAmountBeforeTax
          }
        }
        documents {
          id
          fileId
          type
        }
        previousPolicyId
      }

      lease {
        id
        propertyId
        leaseStartDate
        leaseEndDate
        monthlyRent
        addressUnit
        rentCoverage
        depositsCoverage
        freeRent
        prepaidRent

        property {
          displayName
          mainAddress
          state
          city
          zipCode
          primaryRole {
            contact {
              firstName
              lastName
              email
              phoneNumber
            }
          }
          defaultCoverageRules {
            denied {
              lgCoverage
              sdrCoverage
              sdrCoverageType
            }
            conditional {
              lgCoverage
              sdrCoverage
              sdrCoverageType
            }
            approved {
              lgCoverage
              sdrCoverage
              sdrCoverageType
            }
          }
        }
      }

      contact {
        firstName
        lastName
        email
        phoneNumber
      }

      deal {
        id
      }

      invitation {
        type
      }

      reply {
        answers {
          fieldId
          value
        }
      }

      payment {
        amountPaid
        amountRefunded
        outstandingAmount
        history {
          id
          status
          paymentMethod
          amount
          date
        }
      }

      quote {
        # for payment section
        premiumAmount
        premiumAmountWithoutUpfrontDiscount
        lgPremiumAmountWithoutUpfrontDiscount
        sdrPremiumAmountWithoutUpfrontDiscount

        penalSum
        amountSDR
        coverageMonths
        premiumRate
        rateFilingData
        riskCategory
        carrier
        premium
        addRiskPremium
        lgPremiumAmount
        sdrPremiumAmount
      }

      renterProvidedData {
        leaseMonths
        monthlyRent
        apartmentNumber
      }

      settings {
        comment
        isMonthlyPayment
        isVouchedPremium
        firstMonthlyPaymentDate
      }
    }
  }
`;

export function useApplicationDetails(applicationId: number, addDefaultCoverageRules = false): Result {
  const { data, loading, error, refetch } = useQuery<ApplicationDetailsResponse>(
    addDefaultCoverageRules ? ApplicationDetailsWithPropertyCoverages : ApplicationDetails,
    {
      variables: { applicationId },
    },
  );
  return { application: data?.application, loading, error, refetch };
}
