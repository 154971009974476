import { InfoContainer, Box } from '@theguarantors/ui-kit-v3';
import React, { useMemo, ReactElement } from 'react';

import { formatPhoneNumber } from '../../../../utils/format-phone-number';
import { QuestionnaireReplyAnswer, FieldId } from '../../../../api/api-types';
import { CurrentAddress, QuestionnaireInfoProps } from '../../details.types';
import { ReadableAnswers, TRUE_STR, FALSE_STR, residencyDisplayAnswers } from '../../details.constants';
import { formatStringDate, getAmountString, getInfoContainerDataRow } from '../../details.utils';
import CheckIcon from '../../../../assets/img/check.svg';
import CloseIcon from '../../../../assets/img/close.svg';

const getAnswerMap = (answers: QuestionnaireReplyAnswer[]): Map<FieldId, string> => {
  const answerMap = new Map<FieldId, string>();
  for (const answer of answers) {
    answerMap.set(answer.fieldId, answer.value);
  }

  return answerMap;
};

export const undefinedToNullString = (val: string | undefined): string => val ?? ReadableAnswers.NULL;
export const boolStringToReadableString = (val: string) => {
  if (val === TRUE_STR) {
    return ReadableAnswers.YES;
  }

  if (val === FALSE_STR) {
    return ReadableAnswers.NO;
  }

  return val;
};

export const normalizeHasSSN = (val: string) => {
  if (val === TRUE_STR) {
    return ReadableAnswers.NO;
  }

  if (val === FALSE_STR) {
    return ReadableAnswers.YES;
  }

  return val;
};

export const getReadablePhoneNumber = (phone: string | undefined): string =>
  phone ? formatPhoneNumber(phone) : ReadableAnswers.NULL;
export const getReadableDOB = (DOB: string | undefined): string => (DOB ? formatStringDate(DOB) : ReadableAnswers.NULL);

export const getReadableIncome = (val: string | undefined): string =>
  val ? getAmountString(Number(val)) : ReadableAnswers.NULL;
export const getAddressString = (addressJson: string | undefined) => {
  try {
    const address = JSON.parse(addressJson as string) as CurrentAddress;
    return `${address.lineOne} ${address.city}, ${address.region} ${address.postal}`;
  } catch {
    return ReadableAnswers.NULL;
  }
};

const boolToIconElement = (val?: boolean, alt = ''): string | ReactElement => {
  if (typeof val === 'boolean') {
    return <img src={val ? CheckIcon : CloseIcon} alt={alt} />;
  }

  return ReadableAnswers.NULL;
};

const getResidencyDisplayUnswer = (value: string | undefined) => {
  const displayAnswer = residencyDisplayAnswers[value ?? ''];
  return displayAnswer ?? ReadableAnswers.NULL;
};

export const QuestionnaireInfo: React.FC<QuestionnaireInfoProps> = ({ answers, reportData, ...props }) => {
  const answerMap = useMemo(() => getAnswerMap(answers), [answers]);
  const contentData = useMemo(
    () => [
      getInfoContainerDataRow(
        'Phone Number',
        <Box data-testid="auto_ques_phone" className="fs-mask">
          {getReadablePhoneNumber(answerMap.get(FieldId.PHONE))}
        </Box>,
      ),
      getInfoContainerDataRow(
        'Date of Birth',
        <Box data-testid="auto_ques_dob" className="fs-mask">
          {getReadableDOB(answerMap.get(FieldId.DOB))}
        </Box>,
      ),
      getInfoContainerDataRow(
        'Residency',
        <Box data-testid="auto_ques_us_resident">
          {getResidencyDisplayUnswer(
            answerMap?.get(FieldId.US_RESIDENT)?.toLowerCase() ??
              answerMap?.get(FieldId.RESIDENCY_STATUS)?.toLowerCase(),
          )}
        </Box>,
      ),
      getInfoContainerDataRow(
        'Employment Status',
        <Box data-testid="auto_ques_employment_status">
          {undefinedToNullString(answerMap.get(FieldId.EMPLOYMENT_STATUS))}
        </Box>,
      ),
      getInfoContainerDataRow(
        'Annual Income',
        <Box data-testid="auto_ques_annual_income">{getReadableIncome(answerMap.get(FieldId.INCOME))}</Box>,
      ),
      getInfoContainerDataRow(
        'Has SSN',
        <Box data-testid="auto_ques_has_ssn">
          {normalizeHasSSN(undefinedToNullString(answerMap.get(FieldId.NO_SSN)))}
        </Box>,
      ),
      getInfoContainerDataRow(
        'Credit Score',
        reportData?.ficoScore ? (
          <Box data-testid="auto_ques_credit_score">{reportData?.ficoScore.toString()}</Box>
        ) : (
          <Box data-testid="auto_ques_credit_score">{ReadableAnswers.NULL}</Box>
        ),
      ),
      getInfoContainerDataRow(
        'First Time Renter',
        <Box data-testid="auto_ques_first_time_renter">
          {boolStringToReadableString(undefinedToNullString(answerMap.get(FieldId.FIRST_TIME_RENTER)))}
        </Box>,
      ),
      getInfoContainerDataRow(
        'Current Address',
        <Box data-testid="auto_ques_current_address">{getAddressString(answerMap.get(FieldId.ADDRESS))}</Box>,
      ),
      getInfoContainerDataRow(
        'SSN Matched',
        <Box data-testid="auto_ques_ssn_matched">{boolToIconElement(reportData?.isSsnMatched, 'ssn-matched')}</Box>,
      ),
      getInfoContainerDataRow(
        'DOB Matched',
        <Box data-testid="auto_ques_dob_matched">
          {boolToIconElement(reportData?.isDateOfBirthMatched, 'dob-matched')}
        </Box>,
      ),
      getInfoContainerDataRow(
        'First Name Matched',
        <Box data-testid="auto_ques_firstname_matched">
          {boolToIconElement(reportData?.isFirstNameMatched, 'first-name-matched')}
        </Box>,
      ),
      getInfoContainerDataRow(
        'Last Name Matched',
        <Box data-testid="auto_ques_lastname_matched">
          {boolToIconElement(reportData?.isLastNameMatched, 'last-name-matched')}
        </Box>,
      ),
      getInfoContainerDataRow(
        'Address Matched',
        <Box data-testid="auto_ques_address_matched">
          {boolToIconElement(reportData?.isAddressMatched, 'address-matched')}
        </Box>,
      ),
    ],
    [answerMap, reportData],
  );

  return (
    <InfoContainer
      color="neutral.main"
      title="Questionnaire Information"
      data={contentData}
      extra={{
        icon: 'lock',
        tooltipTitle: 'This information can`t be edited',
        tooltipId: 'dLargeButton',
      }}
      {...props}
    />
  );
};
