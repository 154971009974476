import {
  APPLICATION_STATUSES,
  ApplicationQuote as BusinessApplicationQuote,
  SCREENING_STATUSES,
} from '@theguarantors/tg-modern-business';
import { ApplicationData } from '../components/deals-list/useDealsData';

export interface CoverageTierRules {
  lgCoverage?: string;
  sdrCoverage?: string;
  sdrCoverageType?: string;
}

export interface DefaultCoverageRulesEntity {
  approved?: CoverageTierRules;
  conditional?: CoverageTierRules;
  denied?: CoverageTierRules;
}

export interface PropertyEntity {
  displayName: string;
  mainAddress: string;
  city: string;
  state: string;
  zipCode: string;
  primaryRole: {
    contact: ContactEntity;
  };
  defaultCoverageRules: DefaultCoverageRulesEntity;
}

export interface LeaseEntity {
  id: number;
  leaseStartDate: string;
  leaseEndDate: string;
  freeMonthsStartDate?: string | null;
  freeMonthsEndDate?: string | null;
  prepaidMonthsStartDate?: string | null;
  prepaidMonthsEndDate?: string | null;
  monthlyRent: number;
  addressUnit: string;
  property: PropertyEntity;
  rentCoverage: number;
  depositsCoverage: number;
  freeRent: number;
  prepaidRent: number;
}

export interface ContactEntity {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface DealEntity {
  id: number;
}

export interface InvitationEntity {
  type: string;
}

export interface RecordCheckFields {
  ficoScore: number;
  isFirstNameMatched: boolean;
  isLastNameMatched: boolean;
  isSsnMatched: boolean;
  isDateOfBirthMatched: boolean;
  addressMatchStatus: string;
  isAddressMatched: boolean;
}

export interface ApplicationNormalizedFields {
  dob: string;
  firstTimeRenter: boolean;
  employmentStatus: string;
  ssn: string;
  residency: string;
  riskCategory: string;
  recordCheck: Partial<RecordCheckFields>;
  creditCheckAuthorization: boolean;
  autoUWVersion?: number;
}

export interface PaymentHistory {
  id: number;
  status: string;
  date: string;
  amount: string;
  paymentMethod: string;
}

export interface ApplicationPayment {
  amountPaid: number | null;
  amountRefunded: number | null;
  outstandingAmount: number | null;
  history: PaymentHistory[];
}

export interface ApplicationSettings {
  comment?: string | null;
  firstMonthlyPaymentDate?: string | null;
  isMonthlyPayment?: boolean;
  isVouchedPremium?: boolean;
}

export interface ApplicationQuote extends Omit<BusinessApplicationQuote, 'carrier'> {
  carrier: string;
  riskCategory: string;
  lgPremiumAmountWithoutUpfrontDiscount?: number;
  sdrPremiumAmountWithoutUpfrontDiscount?: number;
}

export interface ApplicationEntity {
  id: number;
  status: APPLICATION_STATUSES;
  firstName: string;
  lastName: string;
  applicantEmail: string;
  phoneNumber: string;
  lease: LeaseEntity;
  contact: ContactEntity;
  deal: DealEntity;
  invitation: InvitationEntity;
  reply?: QuestionnaireReplyEntity;
  report?: ReportEntity;
  normalizedFields: ApplicationNormalizedFields | null;
  payment: ApplicationPayment;
  quote: ApplicationQuote | null;
  settings: ApplicationSettings | null;
  externalApplicationId?: number;
  lgCoverageOverride?: number | null;
  sdrCoverageOverride?: number | null;
  screeningStatus?: SCREENING_STATUSES | null;
  renterProvidedData?: ApplicationRenterProvidedData | null;
}

export interface SummaryApplicationEntity {
  id: number;
  status: APPLICATION_STATUSES;
  firstName: string;
  lastName: string;
  monthlyRent: number;
  leaseStartDate: string;
  addressUnit: string;
  displayName: string;
  applicantEmail: string;
}

export interface PaginatedSummaryApplications {
  data: ApplicationData[];
  page: number;
  total: number;
  count: number;
  pageCount: number;
}

export enum FieldId {
  FIRST_NAME = 1,
  LAST_NAME = 2,
  DOB = 3,
  PHONE = 4,
  ADDRESS = 5,
  FIRST_TIME_RENTER = 7,
  US_RESIDENT = 8,
  EMPLOYMENT_STATUS = 9,
  INCOME = 10,
  SSN = 11,
  NO_SSN = 12,
  CREDIT_CHECK_AUTHORIZATION = 13,
  RESIDENCY_STATUS = 14,
  CITIENSHIP = 15,
}

export interface QuestionnaireReplyAnswer {
  fieldId: FieldId;
  value: string;
}

export interface QuestionnaireReplyEntity {
  replyId: number;
  completed: boolean;
  answers: QuestionnaireReplyAnswer[];
}

export interface ReportEntity {
  id: number;
  service: string;
  status: string;
  serviceData: ReportServiceData;
  createdAt?: string;
  updatedAt?: string;
  contactId: number;
  referenceType: string;
  referenceId: number;
  provider: ReportProvider;
}

interface ReportServiceData {
  response: ReportData;
}

export interface ReportData {
  ficoScore: number;
  isSsnMatched: boolean;
  ficoReasonsCount: number;
  isLastNameMatched: boolean;
  unpaidCollections: boolean;
  isFirstNameMatched: boolean;
  bankruptcyDateFiled: boolean;
  isDateOfBirthMatched: boolean;
  bankruptcyCurrentDispositionDate: string;
}

interface ReportProvider {
  id: number;
  name: string;
  alias: string;
  dataSchema?: {
    ficoscore: string;
  };
  createdAt?: string;
  updatedAt?: string;
}

export interface PolicyEntity {
  id: number;
  bondNumber?: string;
  status: string;
  carrier?: string;
  deal: Record<string, unknown>;
  lease: {
    [key: string]: any;
    property: Record<string, unknown>;
  };
  contact: Record<string, unknown>;
  applications: Array<Record<string, any>>;
  // should be updated
  premium: Record<string, unknown>;
  // should be updated when payment is defined
  payment: Record<string, unknown>;
  documents: Array<{ type: string; id: number }>;
  startAt: string;
  endAt: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface ApplicationRenterProvidedData {
  leaseMonths: number;
  monthlyRent: number;
  apartmentNumber: string;
}
