import React from 'react';
import './shell.scss';

import { Header } from './components/Header';
import { HeaderV2 } from './components/HeaderV2';
import { Footer } from '@theguarantors/ui-kit-v3/layout/components/Footer';
import { Variation } from '@theguarantors/ui-kit-v3/layout/components/Footer/Footer.types';
import { ShellProps } from './Shell.types';
import { ResponsiveBreakpoint, useResponsive } from '../../hooks';
import clsx from 'clsx';
import { FeatureFlag, useFeatureFlag } from './../../hooks/use-feature-flag';

export const Shell = ({ children }: ShellProps) => {
  const breakpoint = useResponsive();
  const isDesktop = breakpoint === ResponsiveBreakpoint.Desktop;
  const [rebrandV2Msa] = useFeatureFlag(FeatureFlag.REBRAND_V2_MSA);
  const isRebrandV2Msa = rebrandV2Msa === 'on';

  return (
    <div className="shell__container">
      {isRebrandV2Msa ? <HeaderV2 isDesktop={isDesktop} /> : <Header isDesktop={isDesktop} />}
      <div
        className={clsx({
          shell__main: true,
          'shell__main--desktop': isDesktop,
        })}
      >
        {children}
      </div>
      <Footer variation={Variation.PORTAL}>
        <>
          <a href="mailto:success@theguarantors.com">success@theguarantors.com</a>
          <a href="tel:+1(212)266-0020">+1 (212) 266-0020</a>
          <span>1 World Trade Center, 77th Flr, New York, NY 10007</span>
        </>
      </Footer>
    </div>
  );
};
