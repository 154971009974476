import React, { useCallback, useMemo, useState } from 'react';
import {
  CurrentPolicyInfoProps,
  PolicyDocument,
  PolicyResponseLeaseProperty,
  UpdatePolicyActionTypes,
} from '../../details.types';
import { InfoContainer, Button, Modal, Table, Box } from '@theguarantors/ui-kit-v3';
import { ModalVersion } from '@theguarantors/ui-kit-v3/components/Modal';
import {
  formatStringDate,
  getInfoContainerDataRow,
  getAmountString,
  getTruthyValuesCount,
  formatIsoStringDateWithouTimezone,
} from '../../details.utils';
import { Icon } from '@theguarantors/ui-kit-v3/icons';
import { FileLinkComponent } from '../FileLinkComponent/file-link.component';
import { FeatureFlag, useFeatureFlag } from '../../../../hooks/use-feature-flag';
import { PolicyInfoModal } from '../Modals/policy-info-modal';
import { ReadableAnswers } from '../../details.constants';
import { CancelPolicyModal } from '../Modals/cancel-policy-modal';
import { GenerationESDocumentComponent } from './generation-es-document.component';
import { DOCUMENT_TYPES } from '@theguarantors/tg-modern-business';

const getAddressFromLEaseProperty = ({ mainAddress, city, state, zipCode }: PolicyResponseLeaseProperty): string =>
  `${mainAddress} ${city},  ${state} ${zipCode}`;

const getDocument = (documents: PolicyDocument[], documentType: DOCUMENT_TYPES) =>
  documents.find((d) => d.type === documentType);

export const availableUpdatePolicyStatuses = ['active', 'paid', 'amended'];

export const CurrentPolicyInfo: React.FC<CurrentPolicyInfoProps> = ({
  policyData,
  riskCategory,
  policyHistory,
  dealId,
  coverageInputDifferences,
  applicationId,
  isVouchedPremium,
  isMonthlyPayment,
  amountPaid,
  refetch,
  ...props
}) => {
  const [isOpenPolicyHistory, setIsOpenPolicyHistory] = useState(false);
  const [isOpenAmendPolicy, setIsOpenAmendPolicy] = useState(false);
  const [isOpenCancelPolicy, setIsOpenCancelPolicy] = useState(false);
  const [displayChangePolicyForProcessingPaymentFF] = useFeatureFlag(
    FeatureFlag.CHANGE_POLICY_FOR_PROCESSINGPAYMENT_APPS,
  );
  const [, { isEnabled: isManagePremiumRefundForCancellationEnabled }] = useFeatureFlag(
    FeatureFlag.MANAGE_PREMIUM_REFUND_FOR_CANCELLATIONS,
  );
  const [premiumCancellationFee] = useFeatureFlag(FeatureFlag.PREMIUM_CANCELLATION_FEE);
  const [, { isEnabled: bondDeclarationEnabled }] = useFeatureFlag(FeatureFlag.BOND_DEC_PAGE_MSA);
  const [, { isEnabled: sl1Enabled }] = useFeatureFlag(FeatureFlag.SL1_MSA);
  const [, { isEnabled: s21Enabled }] = useFeatureFlag(FeatureFlag.SL2_MSA);
  const [, { isEnabled: fixDisplayedAmountAtPolicyHistoryEnabled }] = useFeatureFlag(
    FeatureFlag.FIX_DISPLAYED_AMOUNT_MSA_PORTAL,
  );
  const [, { isEnabled: displayGaiRow }] = useFeatureFlag(FeatureFlag.SHOW_GAI_ON_ADMIN_PORTAL);

  const isCAState = policyData?.lease?.property?.state === 'CA';
  const allowedPolicyStatusesToUpdate = useMemo(() => [...availableUpdatePolicyStatuses], []);
  const isGenerateBondDeclarationPageAvailable = useMemo(
    () => bondDeclarationEnabled && isCAState,
    [bondDeclarationEnabled, isCAState],
  );
  const isGenerateSL1Available = useMemo(() => sl1Enabled && isCAState, [sl1Enabled, isCAState]);
  const isGenerateSL2Available = useMemo(() => s21Enabled && isCAState, [s21Enabled, isCAState]);

  if (displayChangePolicyForProcessingPaymentFF === 'on') allowedPolicyStatusesToUpdate.push('processing-payment');

  const getColumnProps = useCallback(() => {
    return {
      className: 'policy-history__column',
    };
  }, []);

  const premium = useMemo(() => {
    return isMonthlyPayment
      ? policyData.premium.context?.premiumAmountWithoutUpfrontDiscount
      : policyData.premium.amount;
  }, [isMonthlyPayment, policyData?.premium?.amount, policyData?.premium?.context.premiumAmountWithoutUpfrontDiscount]);

  const premiumAmountBeforeTax = useMemo(() => {
    return isCAState ? policyData?.premium?.context?.premiumAmountBeforeTax : null;
  }, [isCAState, policyData?.premium]);

  const getPremiumFromPolicyHistory = useMemo(
    () => (premium: number, premiumAmountWithoutUpfrontDiscount: number) =>
      isMonthlyPayment ? premiumAmountWithoutUpfrontDiscount : premium,
    [isMonthlyPayment],
  );

  const data = useMemo(() => {
    const rows =
      policyData && riskCategory
        ? [
            getInfoContainerDataRow(<b>Policy Number</b>, <b data-testid="auto_policy_number">{policyData.id}</b>),
            getInfoContainerDataRow('Policy Status', <b data-testid="auto_policy_status">{policyData.status}</b>),
            getInfoContainerDataRow(
              'Bond Number',
              policyData.bondNumber ? (
                <Box className="bond-number__column">
                  <FileLinkComponent
                    title={policyData.bondNumber}
                    documentId={getDocument(policyData.documents, DOCUMENT_TYPES.BOND)?.id}
                    fileId={getDocument(policyData.documents, DOCUMENT_TYPES.BOND)?.fileId}
                    fileName={`BOND-${policyData.bondNumber}.pdf`}
                    dataTestId="auto_policy_bond_number"
                  />
                  {isGenerateBondDeclarationPageAvailable && (
                    <GenerationESDocumentComponent
                      policyId={policyData.id}
                      title="Bond Declaration"
                      dataTestId="bond_declaration"
                      fileName={`${policyData.bondNumber}-Declaration-page.pdf`}
                    />
                  )}
                  {isGenerateSL1Available && (
                    <GenerationESDocumentComponent
                      policyId={policyData.id}
                      title="SL-1"
                      dataTestId="sl_1"
                      fileName={`${policyData.bondNumber}-SL1.pdf`}
                    />
                  )}
                  {isGenerateSL2Available && (
                    <GenerationESDocumentComponent
                      policyId={policyData.id}
                      title="SL-2"
                      dataTestId="sl_2"
                      fileName={`${policyData.bondNumber}-SL2.pdf`}
                    />
                  )}
                </Box>
              ) : (
                ReadableAnswers.NULL
              ),
            ),
            getInfoContainerDataRow(
              'Premium',
              <Box data-testid="auto_policy_premium">
                {getAmountString(premium)}
                {coverageInputDifferences?.premium && (
                  <Icon name="warning" color="warning.main" width="16px" height="16px" ml="xs" />
                )}
              </Box>,
            ),
            getInfoContainerDataRow(
              'Created on',
              <Box data-testid="auto_policy_created_on">{formatIsoStringDateWithouTimezone(policyData.createdAt)}</Box>,
            ),
            getInfoContainerDataRow(
              'Lease Start - Lease End',
              <Box data-testid="auto_policy_lease_start_end" display="flex" alignItems="center">
                {formatStringDate(policyData.lease.leaseStartDate)} - {formatStringDate(policyData.lease.leaseEndDate)}
                {(coverageInputDifferences?.leaseStartDate || coverageInputDifferences?.leaseEndDate) && (
                  <Icon name="warning" color="warning.main" width="16px" height="16px" ml="xs" />
                )}
              </Box>,
            ),
            getInfoContainerDataRow(
              'Unit',
              <Box data-testid="auto_policy_unit" display="flex" alignItems="center">
                {policyData.lease.addressUnit}
                {coverageInputDifferences?.addressUnit && (
                  <Icon name="warning" color="warning.main" width="16px" height="16px" ml="xs" />
                )}
              </Box>,
            ),
            getInfoContainerDataRow(
              'Monthly Rent',
              <Box data-testid="auto_policy_monthly_rent" display="flex" alignItems="center">
                {getAmountString(policyData.premium.context.monthlyRent)}
                {coverageInputDifferences?.monthlyRent && (
                  <Icon name="warning" color="warning.main" width="16px" height="16px" ml="xs" />
                )}
              </Box>,
            ),
            getInfoContainerDataRow(
              'Carrier',
              <Box data-testid="auto_policy_carrier">{policyData.deal.carrier ?? ReadableAnswers.NULL}</Box>,
            ),
            getInfoContainerDataRow(
              'LG Coverage (Mo.)',
              <Box data-testid="auto_policy_lg_coverage">
                {policyData.premium.context.coverageMonths.toString()}{' '}
                {coverageInputDifferences?.lgCoverageOverride && (
                  <Icon name="warning" color="warning.main" width="16px" height="16px" ml="xs" />
                )}
              </Box>,
            ),
            getInfoContainerDataRow(
              'SDR Amount',
              <Box data-testid="auto_policy_sdr_amount">
                {getAmountString(policyData.premium.context.amountSDR)}{' '}
                {coverageInputDifferences?.sdrCoverageOverride && (
                  <Icon name="warning" color="warning.main" width="16px" height="16px" ml="xs" />
                )}
              </Box>,
            ),
            getInfoContainerDataRow(
              'Penal Sum',
              <Box data-testid="auto_policy_penal_sum">{getAmountString(policyData.premium.context.penalSum)}</Box>,
            ),
            getInfoContainerDataRow('Risk Category', <Box data-testid="auto_policy_risk_category">{riskCategory}</Box>),
            getInfoContainerDataRow(
              'Premium Rate',
              <Box data-testid="auto_policy_premium_rate">{policyData.premium.context.premiumRate.toString()}</Box>,
            ),
            getInfoContainerDataRow(
              'Building LLC',
              <Box data-testid="auto_policy_building_llc">{policyData.lease.property.llc}</Box>,
            ),
            getInfoContainerDataRow(
              'Building Address',
              <Box data-testid="auto_policy_building_address">
                {getAddressFromLEaseProperty(policyData.lease.property)}
              </Box>,
            ),
            {
              label: null,
              value: null,
            },
          ]
        : [];

    if (displayGaiRow) {
      const bondNumberIndex = rows.findIndex((row) => row.label === 'Bond Number');

      if (bondNumberIndex !== -1) {
        rows.splice(
          bondNumberIndex + 1,
          0,
          getInfoContainerDataRow(
            'Signed GAI',
            <FileLinkComponent
              title="GAI"
              documentId={getDocument(policyData.documents, DOCUMENT_TYPES.GAI)?.id}
              fileId={getDocument(policyData.documents, DOCUMENT_TYPES.GAI)?.fileId}
              fileName="GAI.pdf"
              data-testid="auto_policy_gai_link"
            />,
          ),
        );
      }
    }

    return rows;
  }, [
    coverageInputDifferences,
    policyData,
    riskCategory,
    isGenerateBondDeclarationPageAvailable,
    isGenerateSL1Available,
    isGenerateSL2Available,
    premium,
    displayGaiRow,
  ]);

  const isLeaseHasDifferences = coverageInputDifferences && getTruthyValuesCount(coverageInputDifferences) > 0;

  const isUpdatePolicyUnavailable = useMemo(
    () => !allowedPolicyStatusesToUpdate.includes(policyData.status),
    [policyData, allowedPolicyStatusesToUpdate],
  );

  if (data.length) {
    data[data.length - 1] = {
      label: (
        <>
          <Modal
            scrollable
            data-testid="policy_history_modal"
            isOpen={isOpenPolicyHistory}
            title={`Deal #${dealId} - Policy History`}
            version={ModalVersion.V2}
            onClose={() => setIsOpenPolicyHistory(false)}
          >
            <Table
              columns={[
                { Header: 'Policy Number', accessor: 'sid' },
                { Header: 'Bond Number', accessor: 'bondNumber' },
                { Header: 'Lease Start - Lease End', accessor: 'leaseDates' },
                { Header: 'Unit', accessor: 'addressUnit' },
                { Header: 'Monthly Rent', accessor: 'monthlyRent' },
                { Header: 'Premium', accessor: 'premiumAmount' },
                { Header: 'LG', accessor: 'lg' },
                { Header: 'SDR', accessor: 'sdr' },
                { Header: 'Penal Sum', accessor: 'penalSum' },
                { Header: 'Policy Status', accessor: 'status' },
              ]}
              data={policyHistory.map((policyData, index) => ({
                sid: {
                  value: policyData.id,
                  customRender: () => (
                    <Box data-testid={`auto_policy_number_${index + 1}`}>{policyData.id.toString()}</Box>
                  ),
                },
                bondNumber: {
                  value: policyData.bondNumber,
                  customRender: () => (
                    <FileLinkComponent
                      title={policyData.bondNumber}
                      documentId={getDocument(policyData.documents, DOCUMENT_TYPES.BOND)?.id}
                      fileId={getDocument(policyData.documents, DOCUMENT_TYPES.BOND)?.fileId}
                      fileName={`BOND-${policyData.bondNumber}.pdf`}
                      dataTestId={`auto_bond_number_${index + 1}`}
                    />
                  ),
                },
                leaseDates: {
                  value: policyData.lease.leaseEndDate,
                  customRender: () => (
                    <Box data-testid={`auto_lease_start_end_${index + 1}`}>
                      {formatStringDate(policyData.lease.leaseStartDate)} -{' '}
                      {formatStringDate(policyData.lease.leaseEndDate)}
                    </Box>
                  ),
                },
                addressUnit: {
                  value: policyData.lease.addressUnit,
                  customRender: () => <Box data-testid={`auto_unit_${index + 1}`}>{policyData.lease.addressUnit}</Box>,
                },
                monthlyRent: {
                  value: policyData.premium.context.monthlyRent,
                  customRender: () => (
                    <Box data-testid={`auto_monthly_rent_${index + 1}`}>{policyData.premium.context.monthlyRent}</Box>
                  ),
                },
                premiumAmount: {
                  value: fixDisplayedAmountAtPolicyHistoryEnabled
                    ? getAmountString(
                        getPremiumFromPolicyHistory(
                          policyData?.premium?.amount,
                          policyData?.premium?.context?.premiumAmountWithoutUpfrontDiscount,
                        ),
                      )
                    : getAmountString(premium),
                  customRender: () => (
                    <Box data-testid={`auto_premium_${index + 1}`}>
                      {fixDisplayedAmountAtPolicyHistoryEnabled
                        ? getAmountString(
                            getPremiumFromPolicyHistory(
                              policyData?.premium?.amount,
                              policyData?.premium?.context?.premiumAmountWithoutUpfrontDiscount,
                            ),
                          )
                        : getAmountString(premium)}
                    </Box>
                  ),
                },
                lg: {
                  value: policyData.premium.context.coverageMonths,
                  customRender: () => (
                    <Box data-testid={`auto_lg_${index + 1}`}>
                      {policyData.premium.context.coverageMonths.toString()}
                    </Box>
                  ),
                },
                sdr: {
                  value: policyData.premium.context.amountSDR,
                  customRender: () => (
                    <Box data-testid={`auto_sdr_${index + 1}`}>
                      {getAmountString(policyData.premium.context.amountSDR)}
                    </Box>
                  ),
                },
                penalSum: {
                  value: policyData.premium.context.penalSum,
                  customRender: () => (
                    <Box data-testid={`auto_penal_sum_${index + 1}`}>
                      {getAmountString(policyData.premium.context.penalSum)}
                    </Box>
                  ),
                },
                status: {
                  value: policyData.status,
                  customRender: () => <Box data-testid={`auto_policy_status_${index + 1}`}>{policyData.status}</Box>,
                },
              }))}
              getColumnProps={getColumnProps}
            />
          </Modal>
          <Box display="flex" justifyContent="start" padding="sm" pl="0">
            <Button
              bType="primary"
              mr="sm"
              disabled={!isLeaseHasDifferences || isUpdatePolicyUnavailable}
              onClick={() => setIsOpenAmendPolicy(true)}
            >
              Amend Policy
            </Button>
            <Button
              bType="primary"
              bVariant="outlined"
              disabled={isUpdatePolicyUnavailable}
              mr="sm"
              onClick={() => setIsOpenCancelPolicy(true)}
            >
              Cancel Policy
            </Button>
          </Box>
          <PolicyInfoModal
            isOpen={isOpenAmendPolicy}
            title="Skip payments?"
            description="You're about to amend this policy. This could result in a refund or additional charge to the renter. Would you like to skip the payments?"
            refetch={refetch}
            applicationId={applicationId}
            id={policyData.id}
            setIsOpen={setIsOpenAmendPolicy}
            actionType={UpdatePolicyActionTypes.AMEND}
          />
          <PolicyInfoModal
            isOpen={isOpenCancelPolicy && !isManagePremiumRefundForCancellationEnabled}
            title="Skip Payments?"
            description="You're about to cancel this policy. This could result in a refund to the renter. Would you like to skip the payments?"
            refetch={refetch}
            id={policyData.id}
            applicationId={applicationId}
            setIsOpen={setIsOpenCancelPolicy}
            actionType={UpdatePolicyActionTypes.CANCEL}
          />
          <CancelPolicyModal
            isOpen={isOpenCancelPolicy && isManagePremiumRefundForCancellationEnabled}
            refetch={refetch}
            id={policyData.id}
            applicationId={applicationId}
            setIsOpen={setIsOpenCancelPolicy}
            actionType={UpdatePolicyActionTypes.CANCEL}
            premiumCancellationFee={premiumCancellationFee}
            premiumAmount={premium}
            premiumAmountBeforeTax={premiumAmountBeforeTax}
            isVouchedPremium={isVouchedPremium}
            isMonthlyPayment={isMonthlyPayment}
            amountPaid={amountPaid}
          />
        </>
      ),
      value: (
        <Box display="flex" justifyContent="end" width="100%">
          <Button
            size="s"
            bType="primary"
            bVariant="ghost"
            color="primary.main"
            style={{ paddingLeft: 0 }}
            data-testid="view_policy_history"
            onClick={() => setIsOpenPolicyHistory(true)}
          >
            <Icon width={16} height={16} name="arrowForward" color="primary.main" />
            View Policy History
          </Button>
        </Box>
      ),
    };
  }

  return (
    <InfoContainer
      color="neutral.main"
      title="Policy"
      data={data}
      dataBlockColProps={{ style: { textTransform: 'capitalize' } }}
      borderColor={isLeaseHasDifferences ? 'warning.main' : 'table.disabledBackground'}
      titleContainerProps={{
        pseudoAfterBackgroundCssVar: isLeaseHasDifferences ? '--color-warning-main' : '--color-primary-main',
      }}
      extra={{
        icon: 'lock',
        tooltipTitle: 'This information can`t be edited',
        tooltipId: 'dLargeButton',
      }}
      data-testid="auto_policy_info"
      {...props}
    />
  );
};
